import React from 'react';
import { withGlobalState } from 'react-globally';
import {isMobile} from 'react-device-detect';
import BallisticsModal from './BallisticsModal';
import ReconVideo from './ReconVideo';
import GIEILogo from '../images/giei_black.png';
import SituLogo from '../images/situ_black.png';
import EAAFLogo from '../images/eaaf_black.png';
import GIEIbloo from '../images/giei_bloo.png';
import Situbloo from '../images/situ_bloo.png';
import EAAFbloo from '../images/eaaf_bloo.png';
import Play from '../images/play.png';

class VideoLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: this.props.globalState.currentLanguage,
      languageButtons: this.props.languageButtons,
      landingVideo: props.globalState.landingVideo,
      data: this.props.data,
      height: 0,
      width: 0,
      hover: false,
      SituSRC: SituLogo,
      GIEISRC: GIEILogo,
      EAAFSRC: EAAFLogo
    };
    this.renderSplashTextDesktop = this.renderSplashTextDesktop.bind(this);
    this.updateLandingVideoState = this.updateLandingVideoState.bind(this);
    this.handleMouseOverSITU = this.handleMouseOverSITU.bind(this);
    this.handleMouseOverEAAF = this.handleMouseOverEAAF.bind(this);
    this.handleMouseOverGIEI = this.handleMouseOverGIEI.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  componentDidUpdate(){
  }

  // <h3>Esta plataforma multimedia, creada a través del trabajo conjunto del Grupo Interdisciplinario de Expertos Independientes (GIEI) para Nicaragua, SITU Investigación, y el Equipo Argentino de Antropología Forense (EAAF), presenta documentación y análisis de 4 eventos de violencia seleccionados por el GIEI que tuvieron lugar en Nicaragua entre el 18 de abril y 30 de mayo del 2018. La plataforma funciona tanto como un archivo visual como una herramiento analítica, utilizando un gran numero de fotos y videos generados por usuarios  para mejorar el entendimiento de hechos complejos.</h3

  reconVid = () =>
  <div className="reconVideoImg">
    <div className="playButton" onClick={() => this.updateVideoState()}>
      <img src={Play} alt="playButton"/>
    </div>
  </div>

  handleMouseOverSITU(){
    this.setState({
      SituSRC: Situbloo
    });
  }

  handleMouseOverEAAF(){
    this.setState({
      EAAFSRC: EAAFbloo
    });
  }

  handleMouseOverGIEI(){
    this.setState({
      GIEISRC: GIEIbloo
    });
  }

  handleMouseOut(){
    this.setState({
      SituSRC: SituLogo,
      EAAFSRC: EAAFLogo,
      GIEISRC: GIEILogo
    });
  }

  logos = () =>
    <div className="logoWrapper">
      <a className="SituLogo" href="http://www.situ.nyc" target="_blank" rel="noopener noreferrer"><img className="SituLogo" src={this.state.SituSRC} onMouseEnter={this.handleMouseOverSITU} onMouseLeave={this.handleMouseOut} alt="Situ" /></a>
      <a className="GIEILogo" href="https://www.gieinicaragua.org" target="_blank" rel="noopener noreferrer"><img className="GIEILogo" src={this.state.GIEISRC} onMouseEnter={this.handleMouseOverGIEI} onMouseLeave={this.handleMouseOut} alt="GIEI" /></a>
      <a className="EAAFLogo" href="https://www.eaaf.org" target="_blank" rel="noopener noreferrer"><img className="EAAFLogo" src={this.state.EAAFSRC} onMouseEnter={this.handleMouseOverEAAF} onMouseLeave={this.handleMouseOut} alt="EAAF" /></a>
    </div>

  platformButton() {
    if(this.props.globalState.currentLanguage === 'spanish') {
      return <a href="http://gieinicaragua-cartografia-violencia.org/#/es"><div className="platformButtonEs"></div></a>
    } else {
      return <a href="http://gieinicaragua-cartografia-violencia.org/"><div className="platformButton"></div></a>
    }
  }

  splashTextEnglish = () => {
  if(isMobile) {
    return <div className="splashText">
      {this.languageButtons()}
      <h1>Nicaragua: March of the Mothers Reconstruction</h1>
      <h2>May 30, 2018</h2>
      <div className="mobileScrollDiv">
        <h3>This event reconstruction analyzes one episode of violence that took place in Nicaragua on May 30, 2018 that was part of the systematic repression of civilian demonstrations. The incidents occurred during the <span className="italics">March of the Mothers</span>, where families of victims and other citizens demonstrated against state actions that had already produced numerous fatalities since protests began on April 18, 2018. <br/><br/> It was created through the joint work of the Interdisciplinary Group of Independent Experts (GIEI) for Nicaragua, SITU Research, and the Argentine Forensic Anthropology Team (EAAF).<br></br><br></br>
        Among other sources of information, two ballistics reports—<span onClick={()=>this.renderBallisticsReport('protestor')}>one analyzing the weapons of protestors</span> and <span onClick={()=>this.renderBallisticsReport('police')}>one analyzing the weapons of police and parapolice forces</span>—were used in the making of this video.<br/><br/>
        In addition, a multimedia platform that serves as both a visual archive and an analytical tool for presenting user-generated photographs and videos has been created to improve our understanding of these complex events.</h3>
      {this.logos()}
      </div>
      {this.platformButton()}
    </div>
    } else {
      return <div className="splashText">
        {this.languageButtons()}
        <h1>Nicaragua: March of the Mothers Reconstruction</h1>
        <h2>May 30, 2018</h2>
          <h3>This event reconstruction analyzes one episode of violence that took place in Nicaragua on May 30, 2018 that was part of the systematic repression of civilian demonstrations. The incidents occurred during the <span className="italics">March of the Mothers</span>, where families of victims and other citizens demonstrated against state actions that had already produced numerous fatalities since protests began on April 18, 2018. <br/><br/> It was created through the joint work of the Interdisciplinary Group of Independent Experts (GIEI) for Nicaragua, SITU Research, and the Argentine Forensic Anthropology Team (EAAF).<br></br><br></br>
          Among other sources of information, two ballistics reports—<span onClick={()=>this.renderBallisticsReport('protestor')}>one analyzing the weapons of protestors</span> and <span onClick={()=>this.renderBallisticsReport('police')}>one analyzing the weapons of police and parapolice forces</span>—were used in the making of this video.<br/><br/>
          In addition, a multimedia platform that serves as both a visual archive and an analytical tool for presenting user-generated photographs and videos has been created to improve our understanding of these complex events.</h3>
        {this.platformButton()}
        {this.logos()}
      </div>
    }
  }

  splashTextSpanish = () => {
    if(isMobile) {
      return <div className="splashText">
        {this.languageButtons()}
        <h1>Nicaragua: Marcha de las Madres Reconstrucción</h1>
        <h2>30 de mayo 2018</h2>
        <div className="mobileScrollDiv">
          <h3>La reconstrucción de este evento analiza un episodio de violencia que tuvo lugar en Nicaragua el 30 de mayo del 2018 y que forma parte de la represión sistemática de las protestas ciudadanas. Este evento sucede, justamente, en el marco de <span className="italics">la Marcha de las Madres</span>, en las que familiares de víctimas y otros ciudadanos se manifestaban en contra de la represión estatal que ya había producido numerosas víctimas fatales a partir del 18 de abril.<br/><br/> El trabajo se realizó en forma conjunta entre el Grupo Interdisciplinario de Expertos Independientes (GIEI) para Nicaragua, SITU Investigacion (SITU Research) y el Equipo Argentino de Antropología Forense (EAAF).<br></br><br></br>
          En la construcción de este video se utilizaron, entre otras fuentes, dos informes balísticos: <span onClick={()=>this.renderBallisticsReport('protestor')}>uno, que analiza las armas que tenían los manifestantes</span> y <span onClick={()=>this.renderBallisticsReport('police')}>otro, que analiza las armas utilizadas por la policía y fuerzas para policiales.</span><br/><br/>
            Para ayudar en la comprensión de estos eventos complejos, se realizó una plataforma multimedia utilizando fotos y videos generados por usuarios, que sirve tanto como archivo visual como herramienta analítica.</h3>
          {this.logos()}
        </div>
        {this.platformButton()}
    </div>
    } else {
      return <div className="splashText">
        {this.languageButtons()}
        <h1>Nicaragua: Marcha de las Madres Reconstrucción</h1>
        <h2>30 de mayo 2018</h2>
          <h3>La reconstrucción de este evento analiza un episodio de violencia que tuvo lugar en Nicaragua el 30 de mayo del 2018 y que forma parte de la represión sistemática de las protestas ciudadanas. Este evento sucede, justamente, en el marco de <span className="italics">la Marcha de las Madres</span>, en las que familiares de víctimas y otros ciudadanos se manifestaban en contra de la represión estatal que ya había producido numerosas víctimas fatales a partir del 18 de abril.<br/><br/> El trabajo se realizó en forma conjunta entre el Grupo Interdisciplinario de Expertos Independientes (GIEI) para Nicaragua, SITU Investigacion (SITU Research) y el Equipo Argentino de Antropología Forense (EAAF).<br></br><br></br>
          En la construcción de este video se utilizaron, entre otras fuentes, dos informes balísticos: <span onClick={()=>this.renderBallisticsReport('protestor')}>uno, que analiza las armas que tenían los manifestantes</span> y <span onClick={()=>this.renderBallisticsReport('police')}>otro, que analiza las armas utilizadas por la policía y fuerzas para policiales.</span><br/><br/>
            Para ayudar en la comprensión de estos eventos complejos, se realizó una plataforma multimedia utilizando fotos y videos generados por usuarios, que sirve tanto como archivo visual como herramienta analítica.</h3>
        {this.platformButton()}
        {this.logos()}
    </div>
    }
}

  renderSplashTextDesktop() {
    switch (this.props.globalState.currentLanguage) {
      case 'english':
      return <>
        {this.reconVid()}
        {this.splashTextEnglish()}
        </>;
      case 'spanish':
      return <>
        {this.reconVid()}
        {this.splashTextSpanish()}
      </>;
      default:
      return null;
    }
  }

  renderSplashTextMobile() {
    switch (this.props.globalState.currentLanguage) {
      case 'english':
      return <>
        {this.reconVid()}
        {this.splashTextEnglish()}
        </>;
      case 'spanish':
      return <>
        {this.reconVid()}
        {this.splashTextSpanish()}
      </>;
      default:
      return null;
    }
  }

  renderBallisticsReport(report){
    let currentLanguage = this.props.globalState.currentLanguage;
    let pdfSource = (report, currentLanguage) => {
      if (report === 'protestor' && currentLanguage === 'spanish') {
        return "https://drive.google.com/viewerng/viewer?embedded=true&url=https://s3.amazonaws.com/eaaf.nicaragua/assets/ballistics_reports/Informe_Nicaragua_Spanish.pdf"
      } else if (report === 'police' && currentLanguage === 'spanish') {
        console.log('pol spanish')
        return "https://drive.google.com/viewerng/viewer?embedded=true&url=https://s3.amazonaws.com/eaaf.nicaragua/assets/ballistics_reports/Report_English.pdf"
      } else if (report === 'protestor' && currentLanguage === 'english') {
        return "https://drive.google.com/viewerng/viewer?embedded=true&url=https://s3.amazonaws.com/eaaf.nicaragua/assets/ballistics_reports/Informe_Nicaragua_English.pdf"
      } else {
        return "https://drive.google.com/viewerng/viewer?embedded=true&url=https://s3.amazonaws.com/eaaf.nicaragua/assets/ballistics_reports/Report_English.pdf"
      }
    }
    this.props.setGlobalState({ballistics_modal_open: true})
    this.props.setGlobalState({pdf: pdfSource(report, currentLanguage)})
  }

  updateVideoState() {
    this.props.setGlobalState({reconVideo_open: true});
    this.renderReconVideoSource();
  }

  renderReconVideoSource(){
    let currentLanguage = this.props.globalState.currentLanguage;
    let vimeoSource = (currentLanguage) => {
      if (currentLanguage === 'spanish') {
        return ('https://player.vimeo.com/video/347120628')
      } else {
        return ('https://player.vimeo.com/video/344577931')
      }
    }
    this.props.setGlobalState({videoSource: vimeoSource(currentLanguage)})
  }

  updateLanguage = (language) => {
      this.props.setGlobalState(prevGlobalState => ({
          currentLanguage: language
      }));
  }

  languageButtons = () =>
      <div className="landingLanguageButtons">
          <p className="spanish" id={this.props.globalState.currentLanguage === 'spanish' ? 'active' : ''} onClick={() => this.updateLanguage('spanish')}>Español</p>
          <p className="slash"> / </p>
          <p className="english" id={this.props.globalState.currentLanguage === 'english' ? 'active' : ''} onClick={() => this.updateLanguage('english')}>English</p>
      </div>

  // mobileDisclaimerText(){
  //   return <CuidadoModal isOpen={this.props.globalState.cuidado_modal_open} />
  // }

  renderBallisticsModal(){
    this.overrideCSS();
    return <BallisticsModal isOpen={this.props.globalState.ballistics_modal_open} />
  }

  renderReconVideo(){
    return <ReconVideo isOpen={this.props.globalState.reconVideo_open} />
  }

  updateLandingVideoState() {
    this.props.setGlobalState({landingVideo: false});
    console.log('triggered');
  }

  overrideCSS(){
    var iframes = document.getElementsByTagName("iframe");

            for (let i = 0; i < iframes.length; i++) {
                var url = iframes[i].getAttribute("src");
                if (url.startsWith("https://docs.google.com/document/d/")) {
                    // create div and replace iframe
                    let d = document.createElement('div');
                    d.classList.add("embedded-doc"); // optional
                    iframes[i].parentElement.replaceChild(d, iframes[i]);

                    // CORS request
                    var xhr = new XMLHttpRequest();
                    xhr.open('GET', url, true);
                    xhr.onload = function() {
                        // display response
                        d.innerHTML = xhr.responseText;
                    };
                    xhr.send();
                }
            }
            console.log(iframes)
  }

  render() {
    return(
      <>
        <div className="videoLanding">
            {this.renderBallisticsModal()}
            {this.renderSplashTextDesktop()}
            {this.renderReconVideo()}
        </div>
      </>
    )
  }
}

export default withGlobalState(VideoLanding);
