import React from 'react';
import { withGlobalState } from 'react-globally';
import './App.css';
import VideoLanding from '../src/components/VideoLanding';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

  componentDidMount() {
      this.updateLanguage();
  };

  updateLanguage(){
    let location = document.location.href;
    let regexHref = location.match(/#(.*)/);
    console.log(regexHref[0])
    if (regexHref[0] === '#/es') {
      this.props.setGlobalState({
        currentLanguage: 'spanish'
      })
    }
  }

  render (){
    return (
      <>
      <VideoLanding/>
      </>
    );
  }

}

export default withGlobalState(Main);
