import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './Main';
import * as serviceWorker from './serviceWorker';
import { Route, HashRouter } from 'react-router-dom';
import { Provider } from 'react-globally';

const initialState = {
  ballistics_modal_open: false,
  currentLanguage: 'english',
  currentPage: null,
  currentPDF: '',
  is_archive: false,
  landingVideo: false,
  mapLoaded: false,
  mediaDataReceived: false,
  mobile_carousel: true,
  reconVideo_open: false,
  orientation: '',
  pdf: ''
}

const App = props => (
  <Provider globalState={initialState}>
      <Route path = {'/'} component ={Main} />
      <Route path = {'/es'} component = {Main} />
  </Provider>
);

ReactDOM.render(<HashRouter><App /></HashRouter>, document.getElementById('root'));

serviceWorker.unregister();
