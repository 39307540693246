import React from 'react';
import { withGlobalState } from 'react-globally';
import Close from '../icons/close1.png';

class BallisticsModal extends React.Component {

  onCloseModal = () => {
    this.props.setGlobalState({ ballistics_modal_open: false });
  };

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    return (
      <>
      <div className="modalBackdrop" onClick={()=> this.onCloseModal()}>
        <img src={Close} onClick={()=> this.onCloseModal()} alt="close"/>
      </div>
        <div className="ballisticsModal">
          <iframe src={this.props.globalState.pdf} frameBorder="0" title="ballistics_report">
          </iframe>
      </div>
      </>
    );
  }
}

export default withGlobalState(BallisticsModal);
