import React from 'react';
import ReactDOM from 'react-dom';
import { withGlobalState } from 'react-globally';
import Close from '../icons/close1.png';

class ReconVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.iFrameRef = React.createRef();
  }

  componentDidMount(){
  }

  componentDidUpdate(){
    const iframe = ReactDOM.findDOMNode(this.vimeo);
    if (iframe) {
      setTimeout(function() {
        iframe.style.background = 'rgba(0, 0, 0, 0)';
        iframe.contentWindow.document.body.style.backgroundColor = 'white';
      }, 2000);
    }
    }


  onCloseModal = () => {
    this.props.setGlobalState({ reconVideo_open: false });
  };

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    return (
      <>

      <div className="videoBackdrop" onClick={()=> this.onCloseModal()}>
        <img src={Close} onClick={()=> this.onCloseModal()} alt="close"/>
      </div>
        <div className="reconVideo">
          <iframe title="reconVideo" ref={this.vimeo} src={this.props.globalState.videoSource} data-vimeo-playsinline="false" controls={false} frameBorder="0" allow="fullscreen" allowFullScreen></iframe>
        </div>
      </>
    );
  }
}

export default withGlobalState(ReconVideo);
